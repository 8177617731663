import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import startCase from 'lodash/startCase'

// Base Page
import BasePage from '@/pages/Base'

// Services
import ProvinceService from '@/services/Province'
import ProductService from '@/services/Products'
import NotificationsService from '@/services/Notifications'
import ProfileService from '@/services/Profile'
import UserService from '@/services/UserSettings'
import moment from 'moment'

@Component({})
export default class Notifications extends BasePage {
  constructor() {
    super()
  }

  listProductsDialog: boolean = false
  listProductsObject: any = {}
  id_user: number = 0
  allTotalProducts: number = 0
  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search By Name...'
  searchHint: string = ''
  searchIcon: string = 'search'

  // Table
  tableHeaders: object[] = [
    {
      text: 'Date',
      align: 'center',
      sortable: true,
      value: 'created_at'
    },
    {
      text: 'Title',
      align: 'center',
      sortable: true,
      value: 'title'
    },
    {
      text: 'Additional Note',
      align: 'center',
      sortable: false,
      value: 'notes'
    },
    {
      text: 'Type',
      align: 'center',
      sortable: false,
      value: 'label'
    },
    {
      text: 'Notification to',
      align: 'center',
      sortable: false,
      value: 'recipient'
    }
  ]
  tableItems: any = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = true

  selectedCountry: string = ''

  searchUser: Function

  notificationsDialog: boolean = false
  notificationsData: any = {
    type: {
      selected: null,
      items: [
        {
          text: 'Info',
          value: 'info'
        },
        {
          text: 'Product',
          value: 'product'
        },
        {
          text: 'Category',
          value: 'category'
        }
      ]
    },
    title: null,
    note: null,
    user: {
      selected: null,
      items: [],
      loading: false,
      keyword: null,
      active: false
    },
    url: null,
    product: {
      items: [],
      selected: null,
      loading: false,
      keyword: null
    },
    category_1: {
      items: [],
      selected: null,
      loading: false,
      keyword: null
    },
    category_2: {
      items: [],
      selected: null,
      loading: false,
      keyword: null
    },
    category_3: {
      items: [],
      selected: null,
      loading: false,
      keyword: null
    }
  }

  searchProduct: Function

  opts: any = {
    params: {
      'filter[level][is]': '1',
      'include': 'child'
    }
  }
  categories_data: any = []
  idCategory: any = []

  async mounted() {
    this.searchProduct = debounce(this.getProducts, 500)
    this.searchUser = debounce(this.getUsers, 500)
  }

  @Watch('notificationsData.user.keyword')
  async onSearchUser() {
    await this.searchUser()
  }

  @Watch('notificationsData.product.keyword')
  async onSearchProduct() {
    this.searchProduct()
  }

  @Watch('tablePagination', { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0)
    this.getNotifications()
  }

  async getNotifications() {
    try {
      this.tableLoading = true
      const opts = {
        params: {
          'page[num]': this.tablePagination.page,
          'page[limit]': this.tablePagination.rowsPerPage,
          sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy
        }
      }

      const responseProfile = await ProfileService.getProfileData()
      const user_id = responseProfile.data.attributes.id

      const response = await NotificationsService.getNotifications(user_id, opts)
      this.tableItems = []
      forEach(response.data.data, dataNotif => {
        const notif = {
          ...dataNotif.attributes,
          date: moment(dataNotif.attributes.created_at).format('DD MMMM YYYY'),
          recipient_name: ''
        }

        this.tableItems.push(notif)
      })

      forEach(this.tableItems, async item => {
        if (item.recipient !== 'all members') {
          const responseUser = await UserService.getOneUser(item.recipient)
          item.recipient_name = responseUser.data.data.attributes.name
        } else {
          item.recipient_name = item.recipient
        }
      })

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  onChangeType() {
    if (this.notificationsData.type.selected === 'product') {
      this.getProducts()
    } else if (this.notificationsData.type.selected === 'category') {
      this.getCategories()
    }
  }

  async getUsers() {
    try {
      this.notificationsData.user.loading = true
      const opts = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[name][like]': ''
        }
      }
      if (this.notificationsData.user.keyword) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.notificationsData.user.keyword
        const responseTotal = await UserService.getUser(opts)
        var textLimit = 'page[limit]'
        opts.params[textLimit] = responseTotal.data.meta.pagination.total
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      const response = await UserService.getUser(opts)
      this.notificationsData.user.items = []
      forEach(response.data.data, user => {
        this.notificationsData.user.items.push(user.attributes)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.notificationsData.user.loading = false
    }
  }

  async getProducts() {
    try {
      this.notificationsData.product.loading = true
      const opts = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'include': 'country'
        }
      }

      if (!isEmpty(this.notificationsData.product.keyword)) {
        var textFilter = 'filter[title][like]'
        opts.params[textFilter] = this.notificationsData.product.keyword
      } else {
        var textFilter = 'filter[title][like]'
        delete opts.params[textFilter]
      }

      const response = await ProductService.getProductsLists(opts)
      this.notificationsData.product.items = []
      forEach(response.data, product => {
        const item = {
          ...product.attributes,
          country: response.included.country[product.attributes.country_id].attributes.name
        }
        this.notificationsData.product.items.push(item)
      })

      if (this.notificationsData.product.selected) {
        this.notificationsData.product.items = [...this.notificationsData.product.items, this.notificationsData.product.selected]
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.notificationsData.product.loading = false
    }
  }

  async getCategories() {
    try {
      this.notificationsData.category_1.loading = true
      const responseCategories = await ProductService.getTopLevelCategories(this.opts)
      const responseData = responseCategories.data.data
      this.categories_data = responseData
      this.notificationsData.category_1.items = []
      forEach(responseData, categoryLevel1 => {
        var level1_item = {
          id: categoryLevel1.attributes.id,
          name: categoryLevel1.attributes.name
        }
        this.notificationsData.category_1.items.push(level1_item)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.notificationsData.category_1.loading = false
    }
  }

  async onCategorylevel1Changed(category) {
    try {
      if (this.notificationsData.category_1.selected) {
        this.notificationsData.category_2.loading = true
        this.idCategory = []
        this.idCategory.push(this.notificationsData.category_1.selected.id)
        const response = await ProductService.getLowLevelCategories(category.id)
        const responseData = response.data.data
        this.notificationsData.category_2.items = []
        this.notificationsData.category_3.items = []
        for (const categoryLevel2 of responseData) {
          if (!isEmpty(categoryLevel2.label) && categoryLevel2.level === 2)
            this.notificationsData.category_2.items.push(categoryLevel2)
        }
        forEach(responseData, categoryLevel2 => {
          if (categoryLevel2.attributes.level === 2) {
            var level2_item = {
              id: categoryLevel2.attributes.id,
              name: categoryLevel2.attributes.name
            }
            this.notificationsData.category_2.items.push(level2_item)
          }
        })
      } else {
        this.notificationsData.category_2.items = []
        this.notificationsData.category_3.items = []
        this.notificationsData.category_2.selected = null
        this.notificationsData.category_3.selected = null
        this.idCategory = []
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.notificationsData.category_2.loading = false
    }
  }

  async onCategorylevel2Changed(category) {
    try {
      if (this.notificationsData.category_2.selected) {
        this.notificationsData.category_3.loading = true
        this.idCategory.push(this.notificationsData.category_2.selected.id)
        const response = await ProductService.getLowLevelCategories(category.id)
        const responseData = response.data.data
        this.notificationsData.category_3.items = []
        for (const categoryLevel3 of responseData) {
          if (!isEmpty(categoryLevel3.label) && categoryLevel3.level === 3)
            this.notificationsData.category_3.items.push(categoryLevel3)
        }
        forEach(responseData, categoryLevel3 => {
          if (categoryLevel3.attributes.level === 3) {
            var level3_item = {
              id: categoryLevel3.attributes.id,
              name: categoryLevel3.attributes.name
            }
            this.notificationsData.category_3.items.push(level3_item)
          }
        })
      } else {
        this.notificationsData.category_3.items = []
        this.notificationsData.category_3.selected = null
        this.idCategory.splice(1)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.notificationsData.category_3.loading = false
    }
  }

  async onCategorylevel3Changed(category) {
    if (this.notificationsData.category_3.selected) {
      this.idCategory.push(this.notificationsData.category_3.selected.id)
    } else {
      this.idCategory.splice(2)
    }
  }

  openCreateDialog() {
    this.notificationsData = {
      type: {
        selected: null,
        items: [
          {
            text: 'Info',
            value: 'info'
          },
          {
            text: 'Product',
            value: 'product'
          },
          {
            text: 'Category',
            value: 'category'
          }
        ]
      },
      title: null,
      note: null,
      user: {
        selected: null,
        items: [],
        loading: false,
        keyword: null,
        active: false
      },
      url: null,
      product: {
        items: [],
        selected: null,
        loading: false,
        keyword: null
      },
      category_1: {
        items: [],
        selected: null,
        loading: false,
        keyword: null
      },
      category_2: {
        items: [],
        selected: null,
        loading: false,
        keyword: null
      },
      category_3: {
        items: [],
        selected: null,
        loading: false,
        keyword: null
      }
    }
    this.notificationsDialog = true
    this.getUsers()
  }

  closeDialog() {
    this.notificationsDialog = false
  }

  async addNotifications() {
    const valid = await this.$validator.validateAll('addPaymentHistory')
    if (valid) {
      try {
        this.showLoading({ text: 'Saving...' })
        this.notificationsDialog = false
        var payload: any = {
          label: this.notificationsData.type.selected,
          title: this.notificationsData.title,
          notes: this.notificationsData.note
        }

        // payload onclick
        if (this.notificationsData.type.selected === 'info' && this.notificationsData.url) {
          payload = {
            ...payload,
            onclick_url: this.notificationsData.url
          }
        } else if (this.notificationsData.type.selected === 'category') {
          var lastCatId = ''
          lastCatId = !isEmpty(this.idCategory) ? last(this.idCategory) : ''
          if (lastCatId) {
            payload = {
              ...payload,
              onclick_id: lastCatId
            }
          }
        } else if (this.notificationsData.type.selected === 'product') {
          payload = {
            ...payload,
            onclick_id: this.notificationsData.product.selected.id
          }
        }

        // payload user
        if (this.notificationsData.user.active) {
          payload = {
            ...payload,
            recipient: this.notificationsData.user.selected
          }
        } else {
          payload = {
            ...payload,
            recipient: 'all members'
          }
        }

        await NotificationsService.setNotifocation(payload).then(response => {
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'green',
            timeout: 1500
          })
          this.getNotifications()
        }).catch(error => {
          this.showSnackbar({
            text: error.response.data.message,
            color: 'red',
            timeout: 1500
          })
        })

      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    } else {
      this.showSnackbar({
        text: 'Please check all fields requirements',
        color: 'red',
        timeout: 2000
      })
    }
  }
}

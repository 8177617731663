import BaseService from '@/services/BaseService'

class NotificationsService extends BaseService {
  constructor() {
    super()
  }

  async getNotifications(user_id, opts = {}) {
    this.endPoint = `api/v2/admin/${user_id}/news`
    return this.get(opts)
  }

  async getOneNotification(user_id, news_id, opts = {}) {
    this.endPoint = `api/v2/admin/${user_id}/news`
    return this.getOne(news_id, opts)
  }

  async setNotifocation(data = {}) {
    this.endPoint = 'api/v2/admin-news'
    return this.post(data)
  }

  // async deleteProvince(id) {
  //     this.endPoint = 'api/v2/provinces'
  //     return this.delete(id)
  // }

  // async updateProvince(id, data = {}) {
  //     this.endPoint = 'api/v2/provinces'
  //     return this.putOne(id, data)
  // }
}
export default new NotificationsService()
